@font-face
{
font-family: 'FZY4JW--GB1-0';
  
  src:  url('https://boom-english.oss-cn-shenzhen.aliyuncs.com/public/share/FZY4JW--GB1-0.woff2') format('woff2'),
        url('https://boom-english.oss-cn-shenzhen.aliyuncs.com/public/share/FZY4JW--GB1-0.woff') format('woff'),
        url('https://boom-english.oss-cn-shenzhen.aliyuncs.com/public/share/FZY4JW--GB1-0.ttf') format('truetype'),
        url('https://boom-english.oss-cn-shenzhen.aliyuncs.com/public/share/FZY4JW--GB1-0.svg#FZY4JW--GB1-0') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

input{
  -webkit-user-select:auto;
  -khtml-user-select:auto;
  -moz-user-select:auto;
  -ms-user-select:auto;
  user-select:auto;
}

